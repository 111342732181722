/* src/components/CheckoutForm.css */

.checkout-form {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .checkout-form h2 {
    color: #006d77;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .StripeElement {
    background-color: white;
    padding: 1rem 1.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .checkout-form button {
    background-color: #006d77;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    margin-top: 1rem;
    transition: background-color 0.3s;
  }
  
  .checkout-form button:hover {
    background-color: #005960;
  }
  
  .checkout-form-error {
    color: #d62828;
    margin-top: 1rem;
  }
  
  /* Add to CheckoutForm.css */

.payment-status {
    margin-top: 1rem;
    color: #006d77;
    text-align: center;
  }

  input, .StripeElement {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .checkout-form {
      padding: 1rem;
    }
  }
  