/* src/components/Footer.css */

footer {
    background-color: #e29578; /* muted lavender */
    padding: 1em;
    color: #ffffff;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
