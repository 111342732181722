/* src/components/BlogPage.css */

.blog-page {
    padding: 2em;
    background-color: #f0f0f0;
}

.blog-page h1 {
    color: #006d77;
    margin-bottom: 1em;
}

/* Add more styles for your blog articles */
/* src/components/BlogPage.css */

.blog-page {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .blog-post-card {
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
  }
  
  .blog-post-card img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .blog-post-card h2 {
    color: #006d77;
  }
  
  .blog-post-card p {
    color: #333;
  }
  
  .blog-post-card a {
    display: inline-block;
    margin-top: 10px;
    color: #006d77;
    text-decoration: none;
  }
  