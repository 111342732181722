/* src/components/ProductsSection.css */

#products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 2em;
    background-color: #ffffff;
}

#products h2 {
    font-family: 'Playfair Display', serif;
    color: #006d77;
    margin-bottom: 1em;
}

.product-card {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card img {
    width: 100%;
    border-radius: 5px;
}
