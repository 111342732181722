/* src/components/HeroSection.css */

#hero {
    background-color: #ffddd2; /* warm peach */
    padding: 2em;
    font-family: 'Playfair Display', serif;
}

#hero h1 {
    font-family: 'Lora', serif;
    font-size: 3em; /* Larger for impact */
    font-weight: 700;
}

#hero p {
    font-size: 1em;
    margin-bottom: 1em;
}

button {
    background-color: #d62828; /* deep magenta */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 700;
    transition: background-color 0.3s ease;
    font-size: 1.2em;
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

button:hover {
    background-color: #fcbf49; /* bright mustard for hover effect */
}
