/* src/components/Header.css */

header {
    background-color: #006d77; /* rich teal */
    color: #ffffff;
    padding: 3em;
    display: flex;
    align-items: center; /* Align items vertically */
    position: relative;
}

.bm-burger-button {
  display: none; /* Initially hidden */
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #ffffff;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #006d77;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #ffffff;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #d9d9d9;
}

.countdown-timer {
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
    padding: 0.5em;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: inline;
}

@media (max-width: 768px) {
    .bm-burger-button {
        display: block; /* Show the hamburger icon on mobile */
    }

    .countdown-timer {
        font-size: 1em; /* Smaller font size for mobile */
        left: 42%;
        transform: translateX(-50%) translateY(-50%);
    }
}
