/* src/components/AboutSection.css */

#about {
    padding: 2em;
    background-color: #ffffff;
   /* background-image: url('https://boxofsucculents.com/images/Succulent-Succulents-Logo-2024-no-text.png'); */
    background-size: cover;
    color: #ffffff; /* Adjust text color for readability */
}

#about h2 {
    font-family: 'Playfair Display', serif;
    color: #006d77;
    margin-bottom: 1em;
}
